<template>
    <ContentBase>
        <h2 style="text-align:center;font-family:'Courier New', Courier, monospace;font-weight: bolder;">电子荷质比实验计算器</h2>
        <hr>
        <CalculateBaseK1>
            <template v-slot:caption>U2为<span style="color:blue">600V</span>时,<span style="color:blue">X轴D-Ud</span>的测量数据</template>
            <template v-slot:r1c1>U2 = 600V</template>
        </CalculateBaseK1>
        <hr>
        <CalculateBaseK1>
            <template v-slot:caption>U2为<span style="color:blue">700V</span>时,<span style="color:blue">X轴D-Ud</span>的测量数据</template>
            <template v-slot:r1c1>U2 = 700V</template>
        </CalculateBaseK1>
        <hr>
        <CalculateBaseK1>
            <template v-slot:caption>U2为<span style="color:blue">600V</span>时,<span style="color:blue">Y轴D-Ud</span>的测量数据</template>
            <template v-slot:r1c1>U2 = 600V</template>
        </CalculateBaseK1>
        <hr>
        <CalculateBaseK1>
            <template v-slot:caption>U2为<span style="color:blue">700V</span>时,<span style="color:blue">Y轴D-Ud</span>的测量数据</template>
            <template v-slot:r1c1>U2 = 700V</template>
        </CalculateBaseK1>
        <hr>
        <CalculateBaseU2U1 />
        <hr>
        <CalculateBaseK2>
            <template v-slot:caption>阳极电压<span style="color:blue">U2=600V</span>时,<span style="color:blue">D-I</span>的测量数据</template>
            <template v-slot:r1c1>U2 = 600V</template>
        </CalculateBaseK2>
        <hr>
        <CalculateBaseK2>
            <template v-slot:caption>阳极电压<span style="color:blue">U2=700V</span>时,<span style="color:blue">D-I</span>的测量数据</template>
            <template v-slot:r1c1>U2 = 700V</template>
        </CalculateBaseK2>
        <hr>
        <CalculateBaseEM />
        <hr>
    </ContentBase>
</template>

<script>
import ContentBase from "@/components/ContentBase.vue";
import CalculateBaseK1 from "@/components/ToolsView/SpecificElectronCharge/CalculateBaseK1.vue";
import CalculateBaseU2U1 from "@/components/ToolsView/SpecificElectronCharge/CalculateBaseU2U1.vue";
import CalculateBaseK2 from "@/components/ToolsView/SpecificElectronCharge/CalculateBaseK2.vue";
import CalculateBaseEM from "@/components/ToolsView/SpecificElectronCharge/CalculateBaseEM.vue";

export default {

    name: 'SpecificElectionCharge',

    components:{
    ContentBase,
    CalculateBaseK1,
    CalculateBaseU2U1,
    CalculateBaseK2,
    CalculateBaseEM,
}
}

</script>

<style scoped>
th, td {
    border: 1px solid lightblue;
    text-align: center;
    padding: 5px;
}

caption {
  caption-side: top;
  padding: .5em;
  text-align: center;
}

hr {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: gray;
    height: 1px;
}
</style>