<template>
    <ContentBase>
        <div class="container">
            <div class="row">
                <div class="col-3 all">
                    序号
                </div>
                <div class="col-6 all">
                    内容
                </div>
                <div class="col-3 all">
                    PDF
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-3 all">
                    1
                </div>
                <div class="col-6 all">
                    <router-link :to="{name: 'Linux'}" >Linux基础</router-link>
                </div>
                <div class="col-3 all">
                    <a href="https://cdn.acautomaton.com/cdn/static/pdf/linux.pdf" download="React基础.pdf">
                        <button type="button" class="btn btn-primary btn-sm">浏览</button>
                    </a>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-3 all">
                    2
                </div>
                <div class="col-6 all">
                    <router-link :to="{name: 'HtmlCssJavaScript'}">Html,Css,Javascript基础</router-link>
                </div>
                <div class="col-3 all">
                    <a href="https://cdn.acautomaton.com/cdn/static/pdf/html_css_javascript.pdf" download="React基础.pdf">
                        <button type="button" class="btn btn-primary btn-sm">浏览</button>
                    </a>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-3 all">
                    3
                </div>
                <div class="col-6 all">
                    <router-link :to="{name: 'React'}">React基础</router-link>
                </div>
                <div class="col-3 all">
                    <a href="https://cdn.acautomaton.com/cdn/static/pdf/react.pdf" download="React基础.pdf">
                        <button type="button" class="btn btn-primary btn-sm">浏览</button>
                    </a>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-3 all">
                    4
                </div>
                <div class="col-6 all">
                    <router-link :to="{name: 'Vue3'}">Vue3基础</router-link>
                </div>
                <div class="col-3 all">
                    <a href="https://cdn.acautomaton.com/cdn/static/pdf/vue3.pdf" download="Vue3基础.pdf">
                        <button type="button" class="btn btn-primary btn-sm">浏览</button>
                    </a>
                </div>
            </div>
        </div>
    </ContentBase>
</template>

<script>
import ContentBase from "../components/ContentBase.vue";

export default {
    name: 'DocsView',
    components: {
        ContentBase,
    }
}
</script>

<style scoped>



.all {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

hr {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: gray;
    height: 1px;
}

a:link {
    text-decoration: none;
}
</style>