<template>
  <ContentBase>
    <div class="all">
      联系邮箱:acautomaton@icloud.com
    </div>
    <hr>
  </ContentBase>
</template>

<script>
import ContentBase from "../components/ContentBase.vue";

export default {
  name: 'AboutView',
  components: {
    ContentBase,
  }
}
</script>

<style scoped>
.all {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
hr {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: gray;
    height: 1px;
}
</style>