<template>
    <ContentBase>
        <h2 style="text-align:center;font-family:'Courier New', Courier, monospace;font-weight: bolder;">密立根油滴实验计算器</h2>
        <div style="text-align:center;font-family:'Courier New', Courier, monospace;font-weight: bolder;">本程序所用公式:
            <img style="width:20%" src="https://cdn.acautomaton.com/cdn/static/images/MillikanOilDrop/formula.png" alt="">
        </div>
        <div style="text-align:center;font-family:'Courier New', Courier, monospace;font-weight: bolder;">输入格式:如输入1.6乘10的-14次方,输入1.6e-14即可</div>
        <hr>
        <span style="border-style:dotted;color: coral;">油滴一</span>
        <CalculateBase/>
        <hr>
        <span style="border-style:dotted;color: coral;">油滴二</span>
        <CalculateBase/>
        <hr>
        <span style="border-style:dotted;color: coral;">油滴三</span>
        <CalculateBase/>
        <hr>
        <span style="border-style:dotted;color: coral;">最终结果</span>
        <div style="text-align:center;font-family:'Courier New', Courier, monospace;font-weight: bolder;color: blue">把上面三个油滴的e的平均值依次复制下来</div>
        <div style="text-align:center;font-family:'Courier New', Courier, monospace;font-weight: bolder;color: lightgrey">为什么不能自动同步?因为作者懒得写跨组件传值QWQ</div>
        <form class="container" style="justify-content: center;">
            <div class="row">
                <div class="col all">
                e的平均值一
                </div>
                <div class="col all">
                    <input v-model="e1" type="text" id="e1" />
                </div>
                <div class="col all">
                e的平均值二
                </div>
                <div class="col all">
                    <input v-model="e2" type="text" id="e2" />
                </div>
                <div class="col all">
                e的平均值三
                </div>
                <div class="col all">
                    <input v-model="e3" type="text" id="e3" />
                </div>
            </div>
            <div class="row" style="color:red">
                <div class="col all">
                e的最终平均值
                </div>
                <div class="col all">
                    {{ ((parseFloat(e1) + parseFloat(e2) + parseFloat(e3)) / 3).toExponential([7]) }}
                </div>
                <div class="col all">
                Er
                </div>
                <div class="col all">
                    {{ ((Math.abs(((parseFloat(e1) + parseFloat(e2) + parseFloat(e3)) / 3) - (1.602e-19)) / (1.602e-19)) * 100).toFixed(5) }}%
                </div>
                <div class="col all">
                delta e
                </div>
                <div class="col all">
                    {{ (((parseFloat(e1) + parseFloat(e2) + parseFloat(e3)) / 3) * (Math.abs(((parseFloat(e1) + parseFloat(e2) + parseFloat(e3)) / 3) - (1.602e-19)) / (1.602e-19))).toExponential([7]) }}
                </div>
            </div>
            <hr>
        </form>
    </ContentBase>
</template>

<script>
import ContentBase from "../../components/ContentBase.vue";
import CalculateBase from "../../components/ToolsView/MillikanOilDrop/CalculateBase.vue";
import { ref } from "vue";

export default {
    name: 'MillikanOilDrop',
    setup() {
        let e1 = ref(''); let e2 = ref(''); let e3 = ref('');

        return {
            e1, e2, e3,
        }
    },
    components: {
    ContentBase,
    CalculateBase,
    },
}
</script>

<style scoped>
.all {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

hr {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: gray;
    height: 1px;
}

a:link {
    text-decoration: none;
}

.row {
    margin-bottom: 1em;
}
</style>