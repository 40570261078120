<template>
    <table style="margin:0 auto;width:100%;border-collapse: collapse;">
        <caption>
            不同阳极电压U2时聚焦电压U1的数值
        </caption>
        <tr style="height:3em">
            <th>U2(V)</th>
            <th>600</th>
            <th>700</th>
            <th>800</th>
            <th>900</th>
            <th>1000</th>
        </tr>
        <tr style="height:3em">
            <th>U1(V)</th>
            <td v-for="i in 5" :key="i">
                <div class="input-group mb-1">
                    <input type="text" class="form-control" aria-label="Username" v-model="r[i]">
                    <span class="input-group-text">V</span>
                </div>
            </td>
        </tr>
        <tr style="height:3em">
            <th>U1平均(V)</th>
            <td>
                {{ ave([...r]).toFixed(2) }}
            </td>
            <th colspan="2" style="color:red">U2平均/U1平均</th>
            <td colspan="2" style="color:red">
                {{ (800 / ave([...r])).toFixed(4) }}
            </td>
        </tr>
    </table>
</template>

<script>
import { ref } from 'vue';
export default {
    setup() {
        let r = ref([]);

        const ave = (x) => {
            let sum = 0;

            for (let i = 1; i <= 5; i++) {
                sum += parseFloat(x[i]);
            }
            sum /= 5;

            return sum;
        }

        return {
            r,
            ave,
        }
    },
}
</script>

<style scoped>
th, td {
    border: 1px solid lightblue;
    text-align: center;
    padding: 5px;
    width: calc(100% / 7);
}

caption {
  caption-side: top;
  padding: .5em;
  text-align: center;
}
</style>