import MillikanOilDropView from '../views/ToolsView/MillikanOilDropView.vue'
import SpectroMeterView from '../views/ToolsView/SpectroMeterView.vue'
import SpecificElectionChargeView from '../views/ToolsView/SpecificElectronChargeView.vue'
import PlanckConstantView from '../views/ToolsView/PlanckConstantView.vue'
import MetalWorkFunctionView from '../views/ToolsView/MetalWorkFunctionView.vue'

let routes = [
    {
      path: '/tools/MillikanOilDrop/',
      name: 'MillikanOilDrop',
      component: MillikanOilDropView,
    },
    {
      path: '/tools/SpectroMeter/',
      name: 'SpectroMeter',
      component: SpectroMeterView,
    },
    {
      path: '/tools/SpecificElectionCharge/',
      name: 'SpecificElectionCharge',
      component: SpecificElectionChargeView,
    },
    {
      path: '/tools/PlanckConstant/',
      name: 'PlanckConstant',
      component: PlanckConstantView,
    },
    {
      path: '/tools/MetalWorkFunction/',
      name: 'MetalWorkFunction',
      component: MetalWorkFunctionView,
    },
];
export default routes;