<template>
    <ContentBase>
        <iframe src='https://cdn.acautomaton.com/cdn/static/html/react.html' style="width:100%; height:700px"></iframe>
    </ContentBase>
</template>

<script>
import ContentBase from "@/components/ContentBase.vue";

export default {

    name: 'ReactView',

    components: {
        ContentBase,
    }
}
</script>

<style scoped>

</style>