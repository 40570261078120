<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <router-link class="navbar-brand title style1" :to="{name: 'home'}">
        <img src="https://cdn.acautomaton.com/cdn/static/images/icon/navbaricon.png" alt=""
             style="width:36px;height:36px;"
             class="d-inline-block align-text-top">
        AC自动机
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
              aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link active" aria-current="page" :to="{name: 'home'}"
                         style="margin:auto 10px">首页
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link active" :to="{name: 'docs'}" style=" margin:auto 10px">资料
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link active" :to="{name: 'tools'}" style=" margin:auto 10px">工具
            </router-link>
          </li>
<!--          <li class="nav-item">-->
<!--            <router-link class="nav-link active" :to="{name: 'ChatGpt'}" style=" margin:auto 10px">ChatGpt</router-link>-->
<!--          </li>-->
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link active" :to="{name: 'about'}">关于</router-link>
          </li>
        </ul>

      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
}
</script>

<style scoped>
.title {
  font-size: x-large;
  font-weight: bolder;
}

.style1 {
  color: darkslategray;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
}
</style>