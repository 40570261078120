<template>
    <ContentBase>
        <hr>
        <div class="all" style="background-color:lightgray;border-radius: 5px;font-family:'Courier New', Courier, monospace;">
            <br><br><br><br>
            <h2 style="font-weight:bolder">404 Not Found</h2>
            <br><br><br><br>
        </div>
        <hr>
        <br><br><br><br>
        <div class="all" style="color:lightgrey">
            不仅网页走丢了,这里还有位同学也走丢了,快来领养他吧
            <br><br>
            <img style="height:200px" src="https://cdn.acautomaton.com/cdn/static/images/404handsome.jpg" alt="">
            <br>
            <hr>
        </div>
    </ContentBase>
</template>

<script>
import ContentBase from "../components/ContentBase.vue";

export default {
    name: 'NotFoundView',
    components: {
        ContentBase,
    }
}
</script>

<style scoped>


.all {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
hr {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: gray;
    height: 1px;
}
</style>