<template>
    <ContentBase>
        <h2 style="text-align:center;font-family:'Courier New', Courier, monospace;font-weight: bolder;">分光计实验计算器</h2>
        <div style="text-align:center;font-family:'Courier New', Courier, monospace;font-weight: bolder;">
            <div>教材上为ψ2-ψ1、θ2-θ1,</div>
            <div>但是电子报告提交的地方写的是ψ1-ψ2、θ2-θ1,且填写顺序不同,</div>
            <div>本表格采用的是电子报告的相减方式,但填写顺序是按教材的顺序,</div>
            <div>注意分辨。</div>
        </div>
        <hr>
        <table style="margin:0 auto;width:100%;border-collapse: collapse;">
            <caption>
                反射法测顶角测量数据
            </caption>
            <tr>
                <th rowspan="2">次数</th>
                <th colspan="7">角度</th>
            </tr>
            <tr>
                <th style="width:15%">φ1</th>
                <th style="width:15%">φ1'</th>
                <th style="width:15%">φ2</th>
                <th style="width:15%">φ2'</th>
                <th style="width:10%">φ1 - φ2</th>
                <th style="width:10%">φ1' - φ2'</th>
                <th style="width:10%">α</th>
            </tr>
            <tr>
                <td>1</td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d111" id="d111">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m111" id="m111">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d111, m111).toFixed(2) }}°
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d112" id="d112">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m112" id="m112">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d112, m112).toFixed(2) }}°
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d113" id="d113">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m113" id="m113">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d113, m113).toFixed(2) }}°
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d114" id="d114">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m114" id="m114">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d114, m114).toFixed(2) }}°
                    </div>
                </td>
                <td style="color:brown">
                    {{ dif(degree(d111, m111), degree(d113, m113)).toFixed(2) }}°
                </td>
                <td style="color:brown">
                    {{ dif(degree(d112, m112), degree(d114, m114)).toFixed(2) }}°
                </td>
                <td style="color:brown">
                    {{ ((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4).toFixed(2) }}°
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d121" id="d121">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m121" id="m121">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d121, m121).toFixed(2) }}°
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d122" id="d122">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m122" id="m122">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d122, m122).toFixed(2) }}°
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d123" id="d123">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m123" id="m123">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d123, m123).toFixed(2) }}°
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d124" id="d124">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m124" id="m124">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d124, m124).toFixed(2) }}°
                    </div>
                </td>
                <td style="color:brown">
                    {{ dif(degree(d121, m121), degree(d123, m123)).toFixed(2) }}°
                </td>
                <td style="color:brown">
                    {{ dif(degree(d122, m122), degree(d124, m124)).toFixed(2) }}°
                </td>
                <td style="color:brown">
                    {{ ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4).toFixed(2) }}°
                </td>
            </tr>
        </table>
        <br>
        <table style="margin:0 auto;width:100%;border-collapse: collapse;">
            <tr>
                <td style="width:10%">
                    α平均值
                </td>
                <td style="width:calc(70% / 3);color:brown">
                    {{
                        (
                            (((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4)
                            +
                            ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2
                        ).toFixed(2)
                    }}°
                </td>
                <td style="width:10%">
                    α误差
                </td>
                <td style="width:calc(70% / 3);color:brown">
                    {{
                        (Math.sqrt((Math.pow(((((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4) + ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2)
                        -
                        ((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4), 2))
                        +
                        (Math.pow(((((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4) + ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2)
                        -
                        ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4), 2)))).toFixed(2)
                    }}°
                </td>
                <td style="width:10%">
                    α结果表示
                </td>
                <td style="width:calc(70% / 3);color:brown">
                    ({{
                        (
                            (((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4)
                            +
                            ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2
                        ).toFixed(2)
                    }}
                    ±
                    {{
                        (Math.sqrt((Math.pow(((((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4) + ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2)
                        -
                        ((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4), 2))
                        +
                        (Math.pow(((((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4) + ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2)
                        -
                        ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4), 2)))).toFixed(2)
                    }})°
                </td>
            </tr>
        </table>
        <hr>
        <table style="margin:0 auto;width:100%;border-collapse: collapse;">
            <caption>
                最小偏向角测量数据
            </caption>
            <tr>
                <th rowspan="2">次数</th>
                <th colspan="7">角度</th>
            </tr>
            <tr>
                <th style="width:15%">θ1</th>
                <th style="width:15%">θ1'</th>
                <th style="width:15%">θ2</th>
                <th style="width:15%">θ2'</th>
                <th style="width:10%">θ1 - θ2</th>
                <th style="width:10%">θ1' - θ2'</th>
                <th style="width:10%">δmin</th>
            </tr>
            <tr>
                <td>1</td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d211" id="d211">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m211" id="m211">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d211, m211).toFixed(2) }}°
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d212" id="d212">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m212" id="m212">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d212, m212).toFixed(2) }}°
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d213" id="d213">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m213" id="m213">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d213, m213).toFixed(2) }}°
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d214" id="d214">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m214" id="m214">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d214, m214).toFixed(2) }}°
                    </div>
                </td>
                <td style="color:brown">
                    {{ dif(degree(d211, m211), degree(d213, m213)).toFixed(2) }}°
                </td>
                <td style="color:brown">
                    {{ dif(degree(d212, m212), degree(d214, m214)).toFixed(2) }}°
                </td>
                <td style="color:brown">
                    {{ ((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2).toFixed(2) }}°
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d221" id="d221">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m221" id="m221">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d221, m221).toFixed(2) }}°
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d222" id="d222">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m222" id="m222">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d222, m222).toFixed(2) }}°
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d223" id="d223">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m223" id="m223">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d223, m223).toFixed(2) }}°
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="d224" id="d224">
                        <span class="input-group-text">°</span>
                        <input type="text" class="form-control" aria-label="Server" v-model="m224" id="m224">
                        <span class="input-group-text">'</span>
                    </div>
                    <div style="color:blue">
                        {{ degree(d224, m224).toFixed(2) }}°
                    </div>
                </td>
                <td style="color:brown">
                    {{ dif(degree(d221, m221), degree(d223, m223)).toFixed(2) }}°
                </td>
                <td style="color:brown">
                    {{ dif(degree(d222, m222), degree(d224, m224)).toFixed(2) }}°
                </td>
                <td style="color:brown">
                    {{ ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2).toFixed(2) }}°
                </td>
            </tr>
        </table>
        <br>
        <table style="margin:0 auto;width:100%;border-collapse: collapse;">
            <tr>
                <td style="width:10%">
                    δ平均值
                </td>
                <td style="width:calc(70% / 3);color:brown">
                    {{
                        (
                            (((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2)
                            +
                            ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2)) / 2
                        ).toFixed(2)
                    }}°
                </td>
                <td style="width:10%">
                    δ误差
                </td>
                <td style="width:calc(70% / 3);color:brown">
                    {{
                        (Math.sqrt((Math.pow(((((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2) + ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2)) / 2)
                        -
                        ((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2), 2))
                        +
                        (Math.pow(((((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2) + ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2)) / 2)
                        -
                        ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2), 2)))).toFixed(2)
                    }}°
                </td>
                <td style="width:10%">
                    δ结果表示
                </td>
                <td style="width:calc(70% / 3);color:brown">
                    ({{
                        (
                            (((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2)
                            +
                            ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2)) / 2
                        ).toFixed(2)
                    }}
                    ±
                    {{
                        (Math.sqrt((Math.pow(((((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2) + ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2)) / 2)
                        -
                        ((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2), 2))
                        +
                        (Math.pow(((((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2) + ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2)) / 2)
                        -
                        ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2), 2)))).toFixed(2)
                    }})°
                </td>
            </tr>
        </table>
        <hr>
        <table style="margin:0 auto;width:100%;border-collapse: collapse;">
            <caption>
                三棱镜玻璃的折射率
            </caption>
            <tr>
                <td style="width:10%">
                    n
                </td>
                <td style="width:calc(70% / 3);color:red">
                    {{
                        (Math.sin(
                            (
                                ((
                                    (((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4)
                                    +
                                    ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2
                                )
                                +
                                (
                                (((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2)
                                +
                                ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2)) / 2
                                ))
                                / 2
                            ) * Math.PI / 180
                        )
                        /
                        Math.sin(
                            (
                                (
                                (((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4)
                                +
                                ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2
                                )
                                / 2
                            ) * Math.PI / 180
                        )).toFixed(2)
                    }}
                </td>
                <td style="width:10%">
                    n误差
                </td>
                <td style="width:calc(70% / 3);color:red">
                    <!-- {{
                        Math.sqrt(
                            Math.pow(
                                Math.sin(
                                    (
                                        (
                                            (((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2)
                                            +
                                            ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2)) / 2
                                        ) / 2
                                    ) * Math.PI / 180
                                )
                                /
                                (
                                    2 * Math.sin(
                                        Math.pow(
                                            (
                                                (((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4)
                                                +
                                                ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2
                                            ) / 2
                                        , 2)
                                    )
                                )
                            , 2)
                            *
                            Math.pow(
                                Math.sqrt((Math.pow(((((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4) + ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2)
                                -
                                ((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4), 2))
                                +
                                (Math.pow(((((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4) + ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2)
                                -
                                ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4), 2)))
                            , 2)
                            +
                            Math.pow(
                                Math.cos(
                                    (
                                        (
                                            (((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2)
                                            +
                                            ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2)) / 2
                                        )
                                        +
                                        (
                                            (((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4)
                                            +
                                            ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2
                                        )
                                    ) / 2 * Math.PI / 180
                                )
                                /
                                (
                                    2 * Math.sin(
                                        (
                                            (((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4)
                                            +
                                            ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2
                                        ) / 2 * Math.PI / 180
                                    )
                                )
                            , 2)
                            *
                            Math.pow(
                                (Math.sqrt((Math.pow(((((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2) + ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2)) / 2)
                                -
                                ((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2), 2))
                                +
                                (Math.pow(((((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2) + ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2)) / 2)
                                -
                                ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2), 2))))
                            , 2)
                        )
                    }} -->
                    bug修不好了,自己敲计算器吧
                </td>
                <td style="width:10%">
                    n结果表示
                </td>
                <td style="width:calc(70% / 3);color:red">
                    {{
                       (Math.sin(
                            (
                                ((
                                    (((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4)
                                    +
                                    ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2
                                )
                                +
                                (
                                (((dif(degree(d211, m211), degree(d213, m213)) + dif(degree(d212, m212), degree(d214, m214))) / 2)
                                +
                                ((dif(degree(d221, m221), degree(d223, m223)) + dif(degree(d222, m222), degree(d224, m224))) / 2)) / 2
                                ))
                                / 2
                            ) * Math.PI / 180
                        )
                        /
                        Math.sin(
                            (
                                (
                                (((dif(degree(d111, m111), degree(d113, m113)) + dif(degree(d112, m112), degree(d114, m114))) / 4)
                                +
                                ((dif(degree(d121, m121), degree(d123, m123)) + dif(degree(d122, m122), degree(d124, m124))) / 4)) / 2
                                )
                                / 2
                            ) * Math.PI / 180
                        )).toFixed(2)
                    }}
                    ±
                    {{
                        
                    }}
                </td>
            </tr>
        </table>
        <hr>
    </ContentBase>
</template>

<script>
import ContentBase from "@/components/ContentBase.vue";
import { ref } from "vue";

export default {
    name: 'SpectroMeter',

    setup() {
        let d111 = ref(''), d112 = ref(''), d113 = ref(''), d114 = ref('');
        let m111 = ref(''), m112 = ref(''), m113 = ref(''), m114 = ref('');
        let d121 = ref(''), d122 = ref(''), d123 = ref(''), d124 = ref('');
        let m121 = ref(''), m122 = ref(''), m123 = ref(''), m124 = ref('');
        let d211 = ref(''), d212 = ref(''), d213 = ref(''), d214 = ref('');
        let m211 = ref(''), m212 = ref(''), m213 = ref(''), m214 = ref('');
        let d221 = ref(''), d222 = ref(''), d223 = ref(''), d224 = ref('');
        let m221 = ref(''), m222 = ref(''), m223 = ref(''), m224 = ref('');
        // d111 = 142, d112 = 322, d113 = 22, d114 = 202;
        // m111 = 10, m112 = 12, m113 = 6, m114 = 8;
        // d121 = 146, d122 = 326, d123 = 26, d124 = 206;
        // m121 = 10, m122 = 12, m123 = 8, m124 = 6;
        // d211 = 82, d212 = 262, d213 = 42, d214 = 222;
        // m211 = 30, m212 = 34, m213 = 51, m214 = 53;
        // d221 = 87, d222 = 263, d223 = 50, d224 = 230;
        // m221 = 47, m222 = 57, m223 = 54, m224 = 50;

        const degree = (d, m) => {
            return (parseFloat(d) + (m / 60));
        }

        const dif = (d1, d2) => {
            return (d1 - d2);
        }

        return {
            d111, d112, d113, d114,
            m111, m112, m113, m114,
            d121, d122, d123, d124,
            m121, m122, m123, m124,
            d211, d212, d213, d214,
            m211, m212, m213, m214,
            d221, d222, d223, d224,
            m221, m222, m223, m224,
            degree, dif,
        }
    },

    components: {
        ContentBase,
    }
}

</script>

<style scoped>
th, td {
    border: 1px solid lightblue;
    text-align: center;
    padding: 5px;
}

caption {
  caption-side: top;
  padding: .5em;
  text-align: center;
}

hr {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: gray;
    height: 1px;
}
</style>