import LinuxView from '../views/DocsView/LinuxView.vue'
import HtmlCssJavaScriptView from '../views/DocsView/HtmlCssJavaScriptView.vue'
import ReactView from '../views/DocsView/ReactView.vue'
import Vue3View from '../views/DocsView/Vue3View.vue'

let routes = [
    {
      path: '/docs/Linux/',
      name: 'Linux',
      component: LinuxView,
    },
    {
      path: '/docs/HtmlCssJavaScript/',
      name: 'HtmlCssJavaScript',
      component: HtmlCssJavaScriptView,
    },
    {
      path: '/docs/React/',
      name: 'React',
      component: ReactView,
    },
    {
      path: '/docs/Vue3/',
      name: 'Vue3',
      component: Vue3View,
    },
];
export default routes;