<template>
    <ContentBase>
        <div class="container">
            <div class="row">
                <div class="col-3 all">
                    序号
                </div>
                <div class="col-9 all">
                    工具
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-3 all">
                    1
                </div>
                <div class="col-9 all">
<!--                    <router-link :to="{name: 'MillikanOilDrop'}">密立根油滴实验计算器</router-link>-->
                    <a href="https://toolbox.acautomaton.com/tool/physicalExperimentCalculator/millikanOilDrop">密立根油滴实验计算器</a>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-3 all">
                    2
                </div>
                <div class="col-9 all">
<!--                    <router-link :to="{name: 'SpectroMeter'}">分光计实验计算器</router-link>-->
                    <a href="https://toolbox.acautomaton.com/tool/physicalExperimentCalculator/spectraMeter">分光计实验计算器</a>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-3 all">
                    3
                </div>
                <div class="col-9 all">
<!--                    <router-link :to="{name: 'SpecificElectionCharge'}">电子荷质比实验计算器</router-link>-->
                    <a href="https://toolbox.acautomaton.com/tool/physicalExperimentCalculator/specificElectronCharge">电子荷质比实验计算器</a>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-3 all">
                    4
                </div>
                <div class="col-9 all">
<!--                    <router-link :to="{name: 'PlanckConstant'}">光电效应测普朗克常数计算器</router-link>-->
                    <a href="https://toolbox.acautomaton.com/tool/physicalExperimentCalculator/planckConstant">光电效应测普朗克常数计算器</a>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-3 all">
                    5
                </div>
                <div class="col-9 all">
<!--                    <router-link :to="{name: 'MetalWorkFunction'}">金属溢出功计算器</router-link>-->
                    <a href="https://toolbox.acautomaton.com/tool/physicalExperimentCalculator/metalWorkFunction">金属溢出功计算器</a>
                </div>
            </div>
            <hr>
        </div>
    </ContentBase>
</template>

<script>
import ContentBase from "../components/ContentBase.vue";

export default {
    name: 'ToolsView',
    components: {
        ContentBase,
    }
}
</script>

<style scoped>



.all {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

hr {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: gray;
    height: 1px;
}

a:link {
    text-decoration: none;
}
</style>