<template>
    <form class="container" style="justify-content: center;">
            <div class="row">
                <div class="col all">
                次数
                </div>
                <div class="col all">
                第一次
                </div>
                <div class="col all">
                第二次
                </div>
                <div class="col all">
                第三次
                </div>
                <div class="col all">
                第四次
                </div>
                <div class="col all">
                第五次
                </div>
            </div>
            <div class="row">
                <div class="col all">
                电压(V)
                </div>
                <div class="col all">
                    <input v-model="v1" type="text" id="v1" />
                </div>
                <div class="col all">
                    <input v-model="v2" type="text" id="v2" />
                </div>
                <div class="col all">
                    <input v-model="v3" type="text" id="v3" />
                </div>
                <div class="col all">
                    <input v-model="v4" type="text" id="v4" />
                </div>
                <div class="col all">
                    <input v-model="v5" type="text" id="v5" />
                </div>
            </div>
            <div class="row">
                <div class="col all">
                时间(s)
                </div>
                <div class="col all">
                    <input v-model="t1" type="text" id="t1" />
                </div>
                <div class="col all">
                    <input v-model="t2" type="text" id="t2" />
                </div>
                <div class="col all">
                    <input v-model="t3" type="text" id="t3" />
                </div>
                <div class="col all">
                    <input v-model="t4" type="text" id="t4" />
                </div>
                <div class="col all">
                    <input v-model="t5" type="text" id="t5" />
                </div>
            </div>
            <div class="row">
                <div class="col all">
                Q(C)
                </div>
                <div class="col all">
                    {{ q(v1, t1).toExponential([7]) }}
                </div>
                <div class="col all">
                    {{ q(v2, t2).toExponential([7]) }}
                </div>
                <div class="col all">
                    {{ q(v3, t3).toExponential([7]) }}
                </div>
                <div class="col all">
                    {{ q(v4, t4).toExponential([7]) }}
                </div>
                <div class="col all">
                    {{ q(v5, t5).toExponential([7]) }}
                </div>
            </div>
            <div class="row">
                <div class="col all">
                N
                </div>
                <div class="col all">
                    {{ n(q(v1, t1)) }}
                </div>
                <div class="col all">
                    {{ n(q(v2, t2)) }}
                </div>
                <div class="col all">
                    {{ n(q(v3, t3)) }}
                </div>
                <div class="col all">
                    {{ n(q(v4, t4)) }}
                </div>
                <div class="col all">
                    {{ n(q(v5, t5)) }}
                </div>
            </div>
            <div class="row">
                <div class="col all">
                e(C)
                </div>
                <div class="col all">
                    {{ (q(v1, t1) / n(q(v1, t1))).toExponential([7]) }}
                </div>
                <div class="col all">
                    {{ (q(v2, t2) / n(q(v2, t2))).toExponential([7]) }}
                </div>
                <div class="col all">
                    {{ (q(v3, t3) / n(q(v3, t3))).toExponential([7]) }}
                </div>
                <div class="col all">
                    {{ (q(v4, t4) / n(q(v4, t4))).toExponential([7]) }}
                </div>
                <div class="col all">
                    {{ (q(v5, t5) / n(q(v5, t5))).toExponential([7]) }}
                </div>
            </div>
            <div class="row" style="color:brown; border-style: dotted;">
                <div class="col all" style="display:flex;margin: 5px auto;">
                    <span>e的平均值</span>
                </div>
                <div class="col all">
                    {{ (((q(v1, t1) / n(q(v1, t1))) + (q(v2, t2) / n(q(v2, t2))) + (q(v3, t3) / n(q(v3, t3))) + (q(v4, t4) / n(q(v4, t4))) + (q(v5, t5) / n(q(v5, t5)))) / 5).toExponential([7])}}
                </div>
            </div>
        </form>
</template>

<script>
import { ref } from 'vue';
export default {
    name: "CalculateBase",
    setup() {
        let v1 = ref(''); let v2 = ref(''); let v3 = ref(''); let v4 = ref(''); let v5 = ref('');
        let t1 = ref(''); let t2 = ref(''); let t3 = ref(''); let t4 = ref(''); let t5 = ref('');

        const q = (v, t) => {
            return 0.6636e-14 / (((parseFloat(t) * (1 + (0.0253 * Math.sqrt(parseFloat(t))))) ** (3 / 2)) * parseFloat(v))
        }

        const n = (q) => {
            if (Math.floor(q / 1.602e-19) === 0) {
                return 1;
            }
            return Math.floor(q / 1.602e-19)    
        }

        return {
            v1, v2, v3, v4, v5,
            t1, t2, t3, t4, t5,
            q, n,
        }
    }
}
</script>

<style>
.all {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.row {
    margin-bottom: 1em;
}

input {
    text-align: center;
}
</style>