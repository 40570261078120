<template>
    <table style="margin:0 auto;width:100%;border-collapse: collapse;">
        <caption>磁聚焦和电子荷质比的测量</caption>
        <tr style="height:3em">
            <th rowspan="2">励磁电流</th>
            <th colspan="2">阳极电压</th>
        </tr>
        <tr style="height:3em">
            <th>700V</th>
            <th>800V</th>
        </tr>
        <tr style="height:3em">
            <th>I正向(A)</th>
            <td>
                <div class="input-group mb-1">
                    <input type="text" class="form-control" aria-label="Username" v-model="r1c1">
                    <span class="input-group-text">A</span>
                </div>
            </td>
            <td>
                <div class="input-group mb-1">
                    <input type="text" class="form-control" aria-label="Username" v-model="r1c2">
                    <span class="input-group-text">A</span>
                </div>
            </td>
        </tr>
        <tr style="height:3em">
            <th>I反向(A)</th>
            <td>
                <div class="input-group mb-1">
                    <input type="text" class="form-control" aria-label="Username" v-model="r2c1">
                    <span class="input-group-text">A</span>
                </div>
            </td>
            <td>
                <div class="input-group mb-1">
                    <input type="text" class="form-control" aria-label="Username" v-model="r2c2">
                    <span class="input-group-text">A</span>
                </div>
            </td>
        </tr>
        <tr style="height:3em">
            <th>I平均(A)</th>
            <td>
                {{ parseFloat(((parseFloat(r1c1) + parseFloat(r2c1)) / 2).toFixed(3)) }}
            </td>
            <td>
                {{ parseFloat(((parseFloat(r1c2) + parseFloat(r2c2)) / 2).toFixed(3)) }}
            </td>
        </tr>
        <tr style="height:3em;color:red">
            <th>电子荷质比e/m(C/kg)</th>
            <td>
                {{ 
                    ((700 / (2 * 0.145 * 0.145 * ((parseFloat(r1c1) + parseFloat(r2c1)) / 2) * ((parseFloat(r1c1) + parseFloat(r2c1)) / 2)))
                    *
                    ((0.234 * 0.234 + 0.09 * 0.09) / (526 * 526))
                    *
                    1e14).toExponential([2])
                }}
            </td>
            <td>
                {{ 
                    ((800 / (2 * 0.145 * 0.145 * ((parseFloat(r1c2) + parseFloat(r2c2)) / 2) * ((parseFloat(r1c2) + parseFloat(r2c2)) / 2)))
                    *
                    ((0.234 * 0.234 + 0.09 * 0.09) / (526 * 526))
                    *
                    1e14).toExponential([2])
                }}
            </td>
        </tr>
    </table>
</template>

<script>
import { ref } from 'vue';

export default {
    setup() {
        let r1c1 = ref(''), r1c2 = ref(''), r2c1 = ref(''), r2c2 = ref('');

        return {
            r1c1, r1c2, r2c1, r2c2,
        }
    },
}
</script>

<style scoped>
th, td {
    border: 1px solid lightblue;
    text-align: center;
    padding: 5px;
    width: calc(100% / 3);
}

caption {
  caption-side: top;
  padding: .5em;
  text-align: center;
}
</style>