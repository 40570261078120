<template>
    <ContentBase>
        <h2 style="text-align:center;font-family:'Courier New', Courier, monospace;font-weight: bolder;">光电效应测普朗克常数计算器</h2>
        <hr>
        <table style="margin:0 auto;width:100%;border-collapse: collapse;">
            <caption>
                截止电压测量数据和普朗克常数
            </caption>
            <tr style="height:3em">
                <th style="width:calc(100% / 6)">波长(nm)</th>
                <td style="width:calc(100% / 6)">365</td>
                <td style="width:calc(100% / 6)">405</td>
                <td style="width:calc(100% / 6)">436</td>
                <td style="width:calc(100% / 6)">546</td>
                <td style="width:calc(100% / 6)">577</td>
            </tr>
            <tr style="height:3em">
                <td>频率(×10<sup>14</sup>Hz)</td>
                <td>8.22</td>
                <td>7.41</td>
                <td>6.88</td>
                <td>5.49</td>
                <td>5.20</td>
            </tr>
            <tr style="height:3em">
                <th>不同波长对应的截止电压(V)</th>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="v1">
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="v2">
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="v3">
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="v4">
                    </div>
                </td>
                <td>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" aria-label="Username" v-model="v5">
                    </div>
                </td>
            </tr>
            <tr style="height:3em">
                <td>截止电压U平均值(V)</td>
                <td>{{ ave_v(v1, v2, v3, v4, v5) }}</td>
                <td>频率v平均值(Hz)</td>
                <td>6.64×10<sup>14</sup>Hz</td>
                <td>普朗克常数绝对误差</td>
                <td>{{ Math.abs(get_h(get_k(v1, v2, v3, v4, v5)) - 6.63e-34).toExponential([2]) }}</td>
            </tr>
            <tr style="height:3em;color:brown;">
                <th>斜率b</th>
                <td>{{ get_k(v1, v2, v3, v4, v5) }}</td>
                <th>普朗克常数实验值h</th>
                <td>{{ get_h(get_k(v1, v2, v3, v4, v5)) }}</td>
                <th>普朗克常数相对误差(%)</th>
                <td>{{ ((Math.abs(get_h(get_k(v1, v2, v3, v4, v5)) - 6.63e-34).toExponential([2]) / 6.63e-34) * 100).toFixed(1) }}%</td>
            </tr>
        </table>
    </ContentBase>
</template>

<script>
import ContentBase from "@/components/ContentBase.vue";
import { ref } from "vue";

export default {
    name: 'PlanckConstant',
    
    setup() {
        let v1 = ref(''), v2 = ref(''), v3 = ref(''), v4 = ref(''), v5 = ref('');
        // v1 = 1.26, v2 = 0.78, v3 = 0.54, v4 = 0.16, v5 = 0.10;

        const ave_v = (v1, v2, v3, v4, v5) => {
            return ((Math.abs(parseFloat(v1)) + Math.abs(parseFloat(v2)) + Math.abs(parseFloat(v3)) + Math.abs(parseFloat(v4)) + Math.abs(parseFloat(v5))) / 5).toFixed(3);
        }

        const get_k = (v1, v2, v3, v4, v5) => {
            return (
                ((Math.abs(parseFloat(v1)) * 8.22 + Math.abs(parseFloat(v2)) * 7.41 + Math.abs(parseFloat(v3)) * 6.88 + Math.abs(parseFloat(v4)) * 5.49 + Math.abs(parseFloat(v5)) * 5.20 - 5 * ave_v(v1, v2, v3, v4, v5) * 6.64)
                /
                (8.22 * 8.22 + 7.41 * 7.41 + 6.88 * 6.88 + 5.49 * 5.49 + 5.20 * 5.20 - 5 * 6.64 * 6.64))
                * 1e-14
            ).toExponential([2])
        }

        const get_h = (k) => {
            return (1.6e-19 * k).toExponential([2])
        }

        return {
            v1, v2, v3, v4, v5,
            ave_v, get_k, get_h,
        }
    },

    components: {
        ContentBase,
    }
}

</script>

<style scoped>
th,
td {
    border: 1px solid lightblue;
    text-align: center;
    padding: 5px;
}

caption {
    caption-side: top;
    padding: .5em;
    text-align: center;
}

hr {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: gray;
    height: 1px;
}
</style>