<template>
  <ContentBase>
    <div class="father">
      <div class="input-group mb-3 children">
        <span class="input-group-text" id="basic-addon1">API Key</span>
        <input type="password" class="form-control" placeholder="请输入API Key" aria-label="Username"
               aria-describedby="basic-addon1" v-model="apikey">
      </div>
    </div>
    <div class="children" style="margin: 0 auto 10px auto; color: crimson" v-if="errorflag===true">
      Error：可能因为 Api密匙错误 / 账户已欠费 / 请求过于频繁 / 没有施加魔法 ...
    </div>
    <div>
      <div class="form-floating children">
        <textarea class="form-control" placeholder="在此输入你想问的问题" id="floatingTextarea2" style="height: 10rem"
                  v-model="question"></textarea>
        <label for="floatingTextarea2">Question</label>
        <button type="button" class="btn btn-light" style="height: 10rem;margin-left: 5px" @click="ask">发送</button>
      </div>
    </div>
    <hr>
    <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0"
         aria-valuemax="100" style="width: 80%; margin: 20px auto">
      <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 100%" v-if="flag===true"></div>
    </div>
    <div>
      <div class="form-floating children">
        <textarea class="form-control" placeholder="ChatGpt" id="floatingTextarea2" style="height: 20rem" disabled
                  v-model="answer"></textarea>
        <label for="floatingTextarea2">ChatGpt</label>
      </div>
    </div>
    <hr>
    <div class="children" style="color: gray">
      已升级至ChatGpt 3.5
    </div>
    <div class="children" style="color: gray">
      连续对话正在开发中，蒟蒻作者还没学会动态组件*_*
    </div>
    <div class="children" style="color: gray">
      数据来源于<a href="https://openai.com" style="color: gray;" class="bottom-link" target="_blank">openai.com</a>，仅供学习交流使用。
    </div>
    <div class="children" style="color: gray">
      本站不对ChatGpt的返回结果负责。
    </div>
  </ContentBase>
</template>

<script>
import ContentBase from "@/components/ContentBase.vue";
import {ref} from "vue";
import axios from "axios";

export default {
  name: "ChatGptView",
  components: {
    ContentBase,
  },
  setup() {
    const apikey = ref();
    const question = ref();
    const answer = ref();

    let flag = ref();
    flag.value = false;
    let errorflag = ref();
    errorflag.value = false;

    // let messages = [];
    //
    // const MapsToJSON = (maps) => {
    //   let result = '['
    //   let flagFirst = true;
    //   maps.forEach(function (map) {
    //     let str = '';
    //     if (flagFirst) {
    //       str += '{';
    //       flagFirst = false;
    //     } else {
    //       str += ',{';
    //     }
    //     let i = 1;
    //     map.forEach(function (item, key, mapObj) {
    //       if (mapObj.size === i) {
    //         str += '"' + key + '":"' + item + '"';
    //       } else {
    //         str += '"' + key + '":"' + item + '",';
    //       }
    //       i++;
    //     });
    //     str += '}';
    //     result += str;
    //   });
    //   return result + ']';
    // }

    const ask = () => {
      flag.value = true;
      errorflag.value = false;

      // let map = new Map();
      // map.set("role", "user");
      // map.set("content", question.value);
      // messages.push(map);
      //
      // console.log(MapsToJSON(messages));

      axios.post('https://api.openai.com/v1/chat/completions', {
        "messages": [{"role":"user","content":question.value}],
        "model": "gpt-3.5-turbo"
      }, {
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + apikey.value}
      }).then(response => {
        answer.value = response.data.choices[0].message.content;
        flag.value = false;
      }).catch((res) => {
        console.log(res);
        flag.value = false;
        errorflag.value = true;
      });
    }
    return {
      ask,
      apikey, question, answer, flag, errorflag
    }
  }
}
</script>

<style scoped>
.father {
  display: flex;
}

.children {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

hr {
  border: 0;
  clear: both;
  display: block;
  width: 96%;
  background-color: gray;
  height: 1px;
}
</style>