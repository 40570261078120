<template>
    <ContentBase>
        <h2 style="text-align:center;font-family:'Courier New', Courier, monospace;font-weight: bolder;">金属电子逸出功计算器</h2>
        <div style="text-align:center;font-family:'Courier New', Courier, monospace;font-weight: bolder;">
            <div>
                该表格需要自己修改的地方
                <b style="weight:bolder;font-size: larger;color: red;">有且仅有</b>
                测量数据和坐标轴的起点值
            </div>
            <div>文件->另存为->下载副本以计算</div>
        </div>
        <hr>
        <iframe src='https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fcdn.acautomaton.com%2Fcdn%2Fstatic%2Fexcel%2FMetalWorkFunction.xlsx&wdOrigin=BROWSELINK' style="width:100%; height:700px"></iframe>
        <hr>
    </ContentBase>
</template>

<script>
import ContentBase from "@/components/ContentBase.vue";

export default {

    name: 'MetalSpillageWork',

    components: {
    ContentBase,
    }
}
</script>

<style scoped>
th,
td {
    border: 1px solid lightblue;
    text-align: center;
    padding: 5px;
}

caption {
    caption-side: top;
    padding: .5em;
    text-align: center;
}

hr {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: gray;
    height: 1px;
}
</style>