<template>
    <table style="margin:0 auto;width:100%;border-collapse: collapse;">
        <caption>
            <slot name="caption"></slot>
        </caption>
        <tr style="height:3em">
            <th style="width:20%" rowspan="2">
                <slot name="r1c1"></slot>
            </th>
            <th>Ud(V)</th>
            <th style="width:10%">3</th>
            <th style="width:10%">6</th>
            <th style="width:10%">9</th>
            <th style="width:10%">12</th>
            <th style="width:10%">15</th>
            <th style="width:10%">18</th>
        </tr>
        <tr style="height:3em">
            <th>D(mm)</th>
            <td v-for="i in 6" :key="i">
                <div class="input-group mb-1">
                    <input type="text" class="form-control" aria-label="Username" v-model="r[i]">
                    <span class="input-group-text">mm</span>
                </div>
            </td>
        </tr>
        <tr style="height:3em">
            <th>D平均(mm)</th>
            <td>
                {{ ave_d([...r]).toFixed(2) }}
            </td>
            <th colspan="3" style="color:red">电偏转灵敏度(mm/V)</th>
            <td colspan="3" style="color:red">
                {{ get_K([...r]).toFixed(4) }}
            </td>
        </tr>
    </table>
</template>

<script>
import { ref } from 'vue';
export default {
    setup() {
        let r = ref([]);

        const ave_d = (x) => {
            let sum = 0;

            for (let i = 1; i <= 6; i++)
                sum += parseFloat(x[i]);
            
            return sum / 6;
        }

        const get_K = (x) => {
            return ave_d(x) / 10.5;
        }

        return {
            r,
            ave_d, get_K,
        }
    },
}
</script>

<style scoped>
th, td {
    border: 1px solid lightblue;
    text-align: center;
    padding: 5px;
}

caption {
  caption-side: top;
  padding: .5em;
  text-align: center;
}
</style>