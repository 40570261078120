import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import DocsView from '../views/DocsView.vue'
import ToolsView from '../views/ToolsView.vue'
import NotFoundView from '../views/NotFoundView.vue'

import ToolsRoutes from './tools.js'
import DocsRoutes from './docs.js'
import ChatGptView from "@/views/ChatGptView.vue";

const HomeRoutes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
  },
  {
    path: '/docs',
    name: 'docs',
    component: DocsView,
  },
  {
    path: '/tools',
    name: 'tools',
    component: ToolsView,
  },
  {
    path: '/ChatGpt',
    name: 'ChatGpt',
    component: ChatGptView,
  },

  {
    path: '/404',
    name: 'NotFoundView',
    component: NotFoundView,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  }
]

const routes = HomeRoutes.concat(ToolsRoutes, DocsRoutes);

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
