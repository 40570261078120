<template>
  <ContentBase>
    <div class="all">
      <br><br>
      <h3 style="font-family:'Courier New', Courier, monospace">
        欢迎访问AC自动机
      </h3>
      <a style="font-family:'Courier New', Courier, monospace"
        href="https://acautomaton.com">https://acautomaton.com</a>
      <br><br>
      <hr>
      <br><br><br><br>
      <br><br>
      <br><br><br><br>
      <hr>
      <p style="display: flex;align-items: center;margin-bottom: 0px;">
        <a class="bottom-link" style="color: gray;" href="https://beian.miit.gov.cn/" target="_blank">赣ICP备2022006483号</a>
        <span style=" font-size:0.5rem">&</span>
          <a style="color: gray;" class="bottom-link" target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36011102000604">
            <img class="record" src="https://cdn.acautomaton.com/cdn/static/images/icon/record.png"
              style="float:left;margin: 0 auto;" alt="" />
            赣公网安备36011102000604号
          </a>
      </p>
    </div>
  </ContentBase>
</template>

<script>
import ContentBase from '../components/ContentBase.vue'

export default {
  name:"HomeView",
  components: {
    ContentBase,
  }
}
</script>

<style scoped>
.all {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

a:link {
  text-decoration: none;
}

hr {
  border: 0;
  clear: both;
  display: block;
  width: 96%;
  background-color: gray;
  height: 1px;
}

.record {
  width: 8%;
}
.bottom-link:link {
  color: gray;
  font-size: 0.5rem;
}

.bottom-link:hover {
  color:black;
}
</style>